import { Link } from '@remix-run/react';
import { useState } from 'react';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import config from '../../config';
import { getStaticAssetPath } from '../../utils/assets';
import { HubspotMeetingsForm } from '../Marketing/HubspotMeetingsForm';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';

function Scheduled(props: { pack: DtoGamePack }) {
  const { pack } = props;
  return (
    <OTPCheckoutLayout pack={pack} progress={80} background={false}>
      <div className='w-full flex justify-center items-center text-white'>
        <div className='w-140 bg-modal rounded-2.5xl border border-secondary px-16 py-10 gap-10 flex flex-col items-center'>
          <div className='text-tertiary font-medium text-2xl text-center'>
            Your call has been scheduled!
          </div>
          <img
            alt='calendar'
            src={getStaticAssetPath('images/calendar-3d-icon.png')}
            className='w-25 h-25'
          />
          <div className='text-center w-72'>
            <strong>
              You’ll receive an email invite with a link to the call.
            </strong>
            <br />
            In the meantime, check out our full library of team building
            experiences!
          </div>

          <Link
            to='/home'
            className='w-96 h-12.5 btn-primary flex items-center justify-center'
          >
            Browse Library
          </Link>
        </div>
      </div>
    </OTPCheckoutLayout>
  );
}

export function OTPCheckoutScheduleHeadcountCall(props: {
  pack: DtoGamePack;
  headcount: number;
}) {
  const { pack, headcount } = props;

  const analytics = useOneTimePurchaseAnalytics();
  const [showScheduled, setShowScheduled] = useState(false);

  const handleSubmit = () => {
    analytics.trackEventPurchaseHeadcountCallScheduled();

    setTimeout(() => {
      setShowScheduled(true);
    }, 3000);
  };

  if (showScheduled) {
    return <Scheduled pack={pack} />;
  }

  const hubspotUrl = new URL(config.misc.eventPlanningHubspotUrl);
  hubspotUrl.searchParams.set('team_size', headcount.toString());

  return (
    <OTPCheckoutLayout pack={pack} progress={20} background={false}>
      <div className='flex gap-12 text-white'>
        <div className='w-90 pt-4'>
          <h2 className='text-xl font-bold text-lp-gray-001'>
            📅 Schedule a Demo
          </h2>
          <p className='mt-4 text-base text-icon-gray'>
            Because your group is larger, we’ll assign an account manager to you
            who will assist to make sure your group has a seamless experience.
          </p>
          <div className='mt-5 w-full bg-dark-gray rounded-1.5lg px-5 py-4'>
            <p className=' text-green-001 font-bold'>
              In this short call we will answer all your questions tell you more
              about Luna Park.
            </p>
            <ul className='mt-8 list-disc list-inside text-sms'>
              <li>A 30 minute Zoom call</li>
              <li>Luna Park walkthrough + FAQs</li>
              <li>Feel free to bring a colleague</li>
            </ul>
          </div>
        </div>

        <HubspotMeetingsForm
          title='Schedule Your Demo Call'
          src={hubspotUrl.toString()}
          className='w-210 h-180'
          onSubmitted={handleSubmit}
        />
      </div>
    </OTPCheckoutLayout>
  );
}
