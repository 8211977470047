import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import { OTPUtils } from '../components/OneTimePurchase';
import { OTPCheckoutScheduleHeadcountCall } from '../components/OneTimePurchase/OTPCheckoutScheduledHeadcountCall';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);

  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));

  return {
    headcount: headcount || 0,
  };
};

export function Component() {
  const { pack } = useCheckoutLoaderData();
  const { headcount } = useLoaderData<typeof clientLoader>();

  useTitle(makeTitle(`Schedule a Demo | Purchase ${pack.name}`));
  return <OTPCheckoutScheduleHeadcountCall pack={pack} headcount={headcount} />;
}
